import React from 'react'
import Blogs from '../posts/data';
import BlogList from '../components/BlogList';
export default function Home() {
  const blogs = Blogs();
  return (
    <>
    <div className='welcome-section hero-section'>
      <div className='container-1'>
        <h1 className='hero-intro'>
          Welcome, I am Nilesh
        </h1>
        <span className='hero-description'>
          I 
          <span> lift</span>,
          <span> code</span>,
          <span> workout</span>,
          procrastinate, not because I want to.
        </span>
        {/* <span className='hero-description'>not because I want to.</span> */}
        <span className='hero-description'>It just happens.</span>
      </div>  
    </div>
    <div className='new-section'>
      <div className='container-2'>
        <div className='col-2'>
          <BlogList title="LATEST ARTICLES" blogs={blogs} />
          <div></div>
        </div>
      </div>
    </div>
    </>
  )
}
