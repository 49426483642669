import React from 'react'
import { Link } from 'react-router-dom'

export default function BlogList({ title, blogs}) {
  return (
    <div className='bloglist'>
       <div className='bloglist-title'>{title}</div>
       {
        blogs.reverse().map((blog, item) => <div className='bloglist-blog'>
          <Link to={"/blog/"+blog.title.replace(/\s+/g, '-').toLowerCase()} >
            <div className='bloglist-blog--title'>
                {blog.title}
            </div>
          </Link>
        </div>)
       }
    </div>
  )
}
