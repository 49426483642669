import React, { useEffect, useState } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

import rehypeRaw from 'rehype-raw';

export default function BlogPost({id}) {

  const [text, setText] = useState('');

  useEffect(() => {
    import(`../posts/${id}.md`)
    .then(res => {
      fetch(res.default).then(res => res.text()).then(res => setText(res));
    })
  }, [])

  return (
    <div className='post-section'>
      <div className='post'>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{text}</ReactMarkdown>
      </div>
    </div>
  )
}
