import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import './sass/main.scss';
import BlogPost from './pages/BlogPost';
import Blogs from './posts/data';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Header />
        <Router>
            <Routes>
                <Route path='/' element={<Home />} />
                {
                    Blogs().map((blog, index) => <Route path={'/blog/'+blog.title.replace(/\s+/g, '-').toLowerCase()} element={<BlogPost id={blog.id} />}/>)
                }
            </Routes>
        </Router>
    </React.StrictMode>
)
